import React from "react";
import loadable from "@loadable/component"
import { StaticImage } from "gatsby-plugin-image";
import {
  SassyText,
  Section,
  SectionWrapper,
} from "../../components/core/commonExports";
import { useMixpanel } from "../../context/MixpanelContext";
import { getQueryParams } from "../../utils/queryParamUtils";
import { MetaComponent } from "../../components/MetaComponent";
import GLPNavbar from "../../components/campaigns/GLPNavbar";
import { getPageName } from "../../utils/getCurrentPageName";
import HeroSection from "../../components/campaigns/HeroSection";
import { HeroBg, HeroSectionContainer, HeroSectionHeading, ModalContainer, StyledBgImage } from "../../components/campaigns/glp5/SharedComponents";
import { HeroSectionData, QuoteModalPlaceholder } from "../../components/campaigns/SharedComponents";
import { LeadFormProvider } from "../../context/LeadFormContext";
import { useCampaignLPStates } from "../../hooks/useCampaignLPStates";

const LeadForm = loadable(() => import("../../components/LeadForm"));
const Footer = loadable(() => import("../../components/campaigns/Footer"), {
  resolveComponent: (components) => components.Footer,
});
const SelfServe = loadable(() => import("../../components/SelfServe"))

const StepsToProtectBusiness = loadable(() => import(/* webpackPrefetch: true */ "../../components/StepsToProtectBusiness/StepsToProtectBusinessGLPs"))
const ExtraBenefits = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/ExtraBenefits"))
const ProtectAgainstRisks = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google5/ProtectAgainstRisks"))
const QuotesFromCompanies = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp6/QuotesFromCompanies"))
const ReviewSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google5/ReviewSection"))
const FooterSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google5/FooterSection"))

const IndexPage = () => {
  const mixpanel = useMixpanel();
  
  const {
    leadformWrapperRef,
    leadformRef,
    scrollToLeadForm,
    hideQuoteCTAs,
    setHideQuoteCTAs,
    screen, 
    setScreen,
    hideHeroSection,
    isSelfServe,
    fetchEstimatedPremium,
    selfServeRef,
    handleCallScheduleClick
  } = useCampaignLPStates()

  // capture and store the query params beforehand
  getQueryParams();

  const pageName = getPageName()

  const handleGetQuoteClick = (e, ctaNumber ="") => {
    mixpanel.track(`Clicked 'Get a Quote'- ${ctaNumber}(${pageName})`);
    scrollToLeadForm()
  };

  const heroSectionDefaultComp = (
    <HeroSectionHeading>
      Securing your business has never been easier
    </HeroSectionHeading>
  )

  return (
    <>
      <MetaComponent loadYellowAiScript={false} loadFbPixelScript={false} />
      <GLPNavbar 
        hideQuoteCTA={hideQuoteCTAs || isSelfServe} 
        showCallHelplineCTA={isSelfServe} 
        handleGetQuoteClick={scrollToLeadForm}
        handleCallScheduleClick={handleCallScheduleClick}
      />
      <LeadFormProvider>
        {isSelfServe ? (
          <SelfServe ref={selfServeRef} fetchEstimatedPremium={fetchEstimatedPremium} fallback={<div style={{height: "100vh", width: "100%"}} />} />
        ) : null}
        <Section background="#ffffff" style={{display: isSelfServe ? "none" : "block", marginTop: '3.8rem', position: "relative"}}>
          {screen !== "hero-image" ? (
            <HeroBg />
          ) : (
            <StyledBgImage>
              <StaticImage
                className="bg-image"
                layout="fullWidth"
                alt=""
                loading="lazy"
                placeholder="#fff"
                src="../../assets/images/campaigns/glp5-new-hero.webp"
              />
            </StyledBgImage>
          )}
          <SectionWrapper mobileStyles={{padding: "24px 0", paddingTop: hideHeroSection ? "0" : "6px"}} desktopStyles={{paddingTop: "40px"}}>
            <HeroSectionData>
              <HeroSectionContainer screen={screen} hideHeroSection={hideHeroSection}>
                <HeroSection 
                  screen={screen} 
                  setScreen={setScreen}
                  heroSectionDefaultComp={heroSectionDefaultComp}
                />
              </HeroSectionContainer>
              <ModalContainer screen={screen} hideHeroSection={hideHeroSection} ref={leadformWrapperRef}>
                <LeadForm 
                  ref={leadformRef}
                  formType="google" 
                  renderAsPopup={false} 
                  setHideQuoteCTAs={setHideQuoteCTAs} 
                  style={{width: 'auto'}} 
                  fallback={<QuoteModalPlaceholder />}
                  setHeroSectionScreen={setScreen} 
                />
              </ModalContainer>
            </HeroSectionData>
          </SectionWrapper>
        </Section>
      </LeadFormProvider>
      {!isSelfServe ? <StepsToProtectBusiness desktopStyles={{background: "#fff"}} /> : null}
      <ExtraBenefits />
      <ProtectAgainstRisks desktopStyles={{marginTop: "0"}} mobileStyles={{marginTop: "3rem"}} />
      <QuotesFromCompanies sectionBg="rgba(37, 39, 123, 0.05)" title={<SassyText>Get Quotes from over 10+ Reputed Insurers</SassyText>} titleStyle={{textTransform: "uppercase"}} mobileStyles={{}} desktopStyles={{}} />
      <ReviewSection />
      <FooterSection handleGetQuoteClick={(e, ctaNumber=1) => handleGetQuoteClick(e, ctaNumber)} />
      <Footer style={{background: "#fff"}} />
    </>
  );
};

export default IndexPage;
