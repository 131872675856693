import styled, { css } from "styled-components"

export const commonBgStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: max(440px, 50%);
`

export const StyledBgImage = styled.div`
  ${commonBgStyles};
  height: 690px;
  transform: translateY(-80px);
  background: linear-gradient(270deg, #C4C4E8 3.29%, #000134 55.72%);
  .bg-image {
    height: 100%;
    opacity: 0.65;
  }
  .gatsby-image-wrapper [data-main-image] {
    transition: none !important;
  }
  
  @media screen and (max-width: 768px){
    height: 340px;
    width: 100%;
    transform: translateY(-108px);
    background: linear-gradient(270deg, #C4C4E8 1.07%, #000755 56.83%);
    .bg-image {
      height: 100%;
      opacity: 0.55;
    }
  }
`

export const HeroBg = styled.div`
  ${commonBgStyles};
  height: 100%;
  background: linear-gradient(0deg, #FEF7F4 0%, rgba(235, 216, 219, 0.892692) 55.37%, rgba(177, 178, 243, 0.5) 100%);
  opacity: 0.4;
  @media screen and (max-width: 768px){
    height: 340px;
    width: 100%;
  }
`

export const HeroSectionContainer = styled.div`
  align-self: flex-start;
  max-width: 610px;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0rem;
    display: ${p => p.screen === "hero-image" ? "flex" : p.hideHeroSection ? "none" : "block"};
    flex-direction: row;
    align-self: center;
    align-items: center;
    padding: 0 12px;
  }
`

export const ModalContainer = styled.div`
  width: min(520px, 100%);
  min-height: ${p => p.screen === "hero-image" ? "50vh" : "auto"};
  @media screen and (max-width: 768px) {
    margin-top: ${p=> p.screen === "hero-image" ? "5rem" : p.hideHeroSection ? "0" : "0.75rem"};
    min-height: 50vh;
  }
`

export const HeroSectionHeading = styled.h1`
  font-family: Dream Orphans;
  font-size: 50px;
  margin-top: 4rem;
  line-height: 62px;
  color: #fff;
  text-shadow: 0px -1px 5px rgba(2, 2, 86, 0.38);
  text-align: center;
  text-transform: uppercase;
  width: min(375px, 100%);
  @media (max-width: 768px) {
    text-align: left;
    font-size: 22px;
    line-height: 28px;
    margin-top: 1.5rem;
    max-width: 180px;
  }
`;